import React from 'react';
import { useLocation, Navigate, Outlet  } from 'react-router-dom';
import { useLoginContext } from '../hooks/useAuthentication';

export const PrivateRoutes = () => {
    const location = useLocation();
    const { isLoggedIn } = useLoginContext();
  
  
    return isLoggedIn 
      ? <Outlet />
      : <Navigate to="/" replace state={{ from: location }} />;
  };

