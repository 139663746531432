/**
 * Dependencies for Remote Components
 */
module.exports = {
    resolve: {
      react: require("react"),
      fallback: {
        stream: require.resolve('stream-browserify'), // Required for "rhea" package
        "http": require.resolve("stream-http"),
        "https": require.resolve("https-browserify")
      }
}
  };
  