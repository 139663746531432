import React, {PropsWithChildren} from 'react';
import { Md5 } from 'ts-md5';

import {
    createRequires,
    createUseRemoteComponent,
    fetchRemoteComponent
  } from "@paciolan/remote-component";
  import { resolve } from "../remote-component.config.js";
// import { Md5 } from 'ts-md5';

interface LoginState {
    isLoggedIn: boolean;
    detailsComponent?: () => JSX.Element | null;    
    login?: (username: string, password: string) => Promise<boolean>;
}
const  loginContext = React.createContext<LoginState>({isLoggedIn: false});

export const useLoginContext = () => React.useContext(loginContext);
  
const requires = createRequires(resolve);

export const useRemoteComponent = createUseRemoteComponent({ requires });

export const LoginProvider: React.FC<PropsWithChildren> =  ({children}) => { 
    const [isLoggedIn, setIsLoggedIn] = React.useState<LoginState>({isLoggedIn: false});
    const login = async (_username: string, password: string) => {

        const SecretPage = await fetchRemoteComponent({ requires, url:   `/static/js/Secret${Md5.hashStr(password.toLocaleLowerCase()+password.toLocaleLowerCase())}.js?rnd=${Math.random().toString(36).substring(7) }`}) ;

        // old new MD5 !!!
        // check out webpack.config.js
        // const secret = await import( /* webpackChunkName: "5f19bb27431b64071d2ac128e695f1ed" */ `../components/Secret${Md5.hashStr(password.toLocaleLowerCase())}`);
        setIsLoggedIn({ detailsComponent:  () => <SecretPage url=''/>, isLoggedIn: true});
        return true;
    }

    return <loginContext.Provider value={{...isLoggedIn, login}}>{children}</loginContext.Provider>
}