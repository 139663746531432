import React from "react";
import { useNavigate } from "react-router-dom";
import { useLoginContext } from "../hooks/useAuthentication";
import { debounce } from "lodash";

import TM from "../assets/Kuanda-TM.png";
import LeftFish from "../assets/Fish_Left.png";

import hintCover from "../assets/hint-cover.png";

import "./LoginPage.css";
import "./HomePage.css";

function LoginPage() {
  const [validHintUrl, setValidHintUrl] = React.useState(false);
  const [hintUrl, setHintUrl] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [showError, setShowError] = React.useState<
    undefined | { errorMessage: string; onHint?: boolean }
  >();

  const {debouncesHintUrl, setHintError} = React.useMemo(() => { 
    const tools=  {
    setHintError: function () {
        setShowError({
          onHint: true,
          errorMessage: "Error: invalid link-hint"
        });
        setValidHintUrl(false);
      },
      debouncesHintUrl: debounce((url: string) => {
        if (!url) {
          return;
        }
        if (!url.match(/^https:\/\/api\.nga\.gov.*default.jpg$/)) {
          tools.setHintError();
          return;
        }
        setHintUrl(url);
      }, 300),

    };
    return tools;
  }, []);

  const { login } = useLoginContext();
  const navigate = useNavigate();
  return (
    <div className="LoginPage">
      <div className="LoginPage-wave"></div>
      <div className="HomePage-BottomBar">
        <img src={TM} alt="TM" />
        <img
          src={LeftFish}
          alt="Left fish"
          style={{ left: 0, bottom: 117, position: "absolute" }}
        />
      </div>
      <div className="LoginPage-FormWrapper">
        <div className="LoginPage-FormBox">
          <div className="LoginPage-Form">
            <div>
              <span className="h2">Welcome to</span>
              <span className="h1">Kuanda Club!</span>
            </div>
            <span className="LoginPage-Error">{showError?.errorMessage}</span>

            <div>
            <span className="Label">PASSCODE</span>
            <input
              id="password"
              autoComplete="off"
              type="password"
              placeholder="*******"
              onChange={(e) => setPassword(e.target.value)}
              className={showError && showError?.onHint !== true ? "LoginPage-Error" : ""}
            />
            </div>
            <div>
            <span className="Label">LOGIN-HINT</span>
            <input
              id="url"
              autoComplete="off"
              type="text"
              placeholder="login hint"
              onChange={(e) => {
                setShowError(undefined);
                debouncesHintUrl(e.target.value);
              }}
              className={showError?.onHint ? "LoginPage-Error" : ""}
            />
            </div>
            <button
              onClick={() => {
                setShowError(undefined);
                login?.("user", password)
                  .then(() => navigate("/ClubPage"))
                  .catch((e) =>
                    setShowError({
                      errorMessage: "Error: invalid password, please try again",
                    })
                  );
              }}
            >
              Enter to the club
            </button>
          </div>
        </div>
        <div className="LoginPage-Hint">

            <img
              id="hintUrl"
              src={hintUrl}
              alt="hint"
              style={{
                zIndex: 1,
                display: validHintUrl ? "block" : "none",
                borderRadius: '50%',
              }}
              onError={() => hintUrl ? setHintError() : {}}
              onLoad={() => setValidHintUrl(true)}
              width={432}
              height={466}
              className="LoginPage-Hint-Img"
            />

          <img id="img" src={hintCover} alt="Left fish" style={{ zIndex: 2 }} />
        </div>
      </div>
    </div>
  );

}

export default LoginPage;
