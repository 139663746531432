import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./page/HomePage";
import LoginPage from "./page/LoginPage";
import ClubPage from "./page/ClubPage";
import { PrivateRoutes } from "./components/PrivateRoute";
import { LoginProvider } from "./hooks/useAuthentication";

function App() {
  return (
    <div className="App">
      <LoginProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/" element={<PrivateRoutes />}>
            <Route path="/ClubPage" element={<ClubPage />} />
            <Route path="*" element={<HomePage />} />
          </Route>
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      </BrowserRouter>
      </LoginProvider>
    </div>
  );
}

export default App;
