import React from "react";
import "./HomePage.css";

import Card1 from '../assets/Card1.png';
import Card2 from '../assets/Card2.png';
import Card3 from '../assets/Card3.png';
import TM from '../assets/Kuanda-TM.png';
import BottomLogo from '../assets/kuanda-logo-72.png';
import { useNavigate } from "react-router-dom";

function jumpTo(hash: string) {
  const element = document.getElementById(hash);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}

function HomePage() {
  const nav = useNavigate();
  
  return (
    <div className="HomePage">
      <div className="HomePage-content">
        <div className="HomePage-topSection">
          <div className="HomePage-NavigationBar">
            <div className="HomePage-NavigationBar-Logo"></div>
            <div className="HomePage-NavigationBar-Buttons">
              <span onClick={() => jumpTo('Journeys')}>Journeys</span>
              <button className="join-the-club-transparent" onClick={() => nav('/login')}>
                <span>Join the club</span>
              </button>
            </div>
          </div>

          <div className="HomePage-topSection-content">
            <span className="h1">Taking fishing to the next level!</span>
            <span className="h2">A unique fishing experience in incredible locations</span>
            <JoinButton style={{ marginTop: 36 }} />
          </div>
        </div>
        <div className="HomePage-DetailsSection">
          <div className="HomePage-RevertHeader">
            <span className="h1">Embark on the Extraordinary:</span>
            <span className="h1">Kuanda's Elite Deep-Dive</span>
            <span className="h1">Fishing Experience</span>
          </div>
          <div className="HomePage-topSection-content">
            <div className="HomePage-DetailsSection-middle-image">
            </div> 
            <JoinButton />
          </div>
        </div>
        <div id="Journeys" className="HomePage-Product">
          <div className="HomePage-RevertHeader">
            <span className="h1">Our Journeys</span>
          </div>
          <div className="HomePage-topSection-content">
            <div className="HomePage-HomePage-Product-gallery">
              <img src={Card1} alt="product" />
              <img src={Card2} alt="product" />
              <img src={Card3} alt="product" />
            </div> 
            <JoinButton style={{ marginTop: 0 }} />
          </div>
        </div>
      </div>
      <div className="HomePage-BottomBar">  
        <img src={TM} alt="TM" />
      </div>
      <img src={BottomLogo} alt="BottomLogo" style={{right: 5, bottom: 2, position: 'absolute'}}/>
    </div>
  );
}

export default HomePage;
function JoinButton({style}: {style?: React.CSSProperties}) {
  const nav = useNavigate();
  return <button className="join-the-club-full" style={style} onClick={() => nav('/login')}>
    <span>Join the club</span>
  </button>;
}

